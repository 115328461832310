<!--  -->
<template>
  <div class="container">
    <el-card>
      <h3 v-show="ruleForm.checkStatus == 2 && $route.query.type == 2">
        拒绝理由：{{ ruleForm.reasons }}
      </h3>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="ruleForm"
      >
        <el-form-item
          prop="bankAccount"
          label-width="135"
          class="el-form-item-line"
        >
          <div class="line">车辆信息</div>
        </el-form-item>
        <el-form-item
          label="车主姓名"
          prop="vehicleOwnerName"
          label-width="135"
        >
          <el-input v-model="ruleForm.vehicleOwnerName" size="small"></el-input>
        </el-form-item>
        <el-form-item label="机械名称" prop="vehicleName" label-width="135">
          <el-input v-model="ruleForm.vehicleName" size="small"></el-input>
        </el-form-item>
        <el-form-item label="机械类型" prop="vehicleType" label-width="135">
          <el-cascader
            :options="carStatusList"
            @change="carStatusChange"
            v-model="ruleForm.vehicleType"
            :props="optionProps"
            size="small"
            style="width: 50%"
            ref="cascaderAddr"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="机械品牌" prop="vehicleBrandId" label-width="135">
          <el-select
            size="small"
            v-model="ruleForm.vehicleBrandId"
            placeholder="请选择"
            @change="changeBrandId"
          >
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.brandName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机械型号" prop="vehicleModel" label-width="135">
          <el-input v-model="ruleForm.vehicleModel" size="small"></el-input>
        </el-form-item>
        <el-form-item
          label="出厂时间"
          prop="vehicleProductionDate"
          label-width="135"
        >
          <el-date-picker
            size="small"
            value-format="yyyy-MM-dd"
            v-model="ruleForm.vehicleProductionDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="车辆识别码"
          prop="vehicleIdentificationCode"
          label-width="135"
        >
          <el-input
            v-model="ruleForm.vehicleIdentificationCode"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="发动机号码"
          prop="vehicleEngineNumber"
          label-width="135"
        >
          <el-input
            v-model="ruleForm.vehicleEngineNumber"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceName" label-width="135">
          <el-input v-model="ruleForm.deviceName" size="small"></el-input>
        </el-form-item>
        <el-form-item label="设备编号" prop="deviceSn" label-width="135">
          <el-input
            v-model="ruleForm.deviceSn"
            maxlength="20"
            size="small"
          ></el-input>
        </el-form-item>

        <div style="width: 100%">
          <el-form-item
            label="所在地定位"
            prop="vehicleProvince"
            label-width="135"
            style="width: 70%"
          >
            <div class="address_box">
              <el-select
                v-model="ruleForm.vehicleProvince"
                placeholder="请选择省"
                @change="provinceChange"
                size="small"
              >
                <el-option
                  v-for="(item, index) in provinceList"
                  :key="index"
                  :label="item.cityName"
                  :value="item.cityId"
                ></el-option>
              </el-select>
              <el-select
                v-model="ruleForm.vehicleCity"
                placeholder="请选择市"
                @change="cityChange"
                size="small"
              >
                <el-option
                  v-for="(item, index) in cityList"
                  :key="index"
                  :label="item.cityName"
                  :value="item.cityId"
                ></el-option>
              </el-select>
              <el-select
                v-model="ruleForm.vehicleCounty"
                placeholder="请选择区"
                @change="areaChange"
                size="small"
              >
                <el-option
                  v-for="(item, index) in areaList"
                  :key="index"
                  :label="item.cityName"
                  :value="item.cityId"
                ></el-option>
              </el-select>
            </div>
            <el-input
              type="textarea"
              :rows="2"
              v-model="ruleForm.vehicleLocation"
              placeholder="详细地址"
              style="width: 100%; margin-top: 10px; margin-left: 135px"
            ></el-input>
          </el-form-item>
        </div>
        <div style="width: 100%">
          <el-form-item
            label="租赁方式"
            prop="leaseType"
            label-width="135"
            style="width: 100%"
          >
            <el-select
              size="small"
              v-model="ruleForm.leaseType"
              placeholder="请选择"
              @change="changeLeaseType($event)"
              style="width: 25%"
            >
              <el-option
                v-for="v in leaseTypeList"
                :key="v.id"
                :label="v.name"
                :value="v.id"
              >
              </el-option>
            </el-select>
            <!-- <el-input
              v-model="ruleForm.leaseWork"
              size="small"
              style="width: 21%; margin: 0 20px"
              v-if="ruleForm.leaseType != ''"
            >
            </el-input> -->
            <el-select
              size="small"
              v-model="ruleForm.leaseUnit"
              placeholder="请选择"
              v-if="ruleForm.leaseTypeCode == 3"
              style="width: 21%; margin-left: 20px"
              @change="changeLeaseUnit($event)"
            >
              <el-option
                v-for="v in firmNumList"
                :key="v.id"
                :label="v.dictName"
                :value="v.dictId"
              >
              </el-option>
            </el-select>
            <el-input
              v-if="ruleForm.leaseType != '' && ruleForm.leaseTypeCode != 3"
              v-model="ruleForm.leaseUnit"
              size="small"
              style="width: 21%; margin-left: 20px"
              disabled
            ></el-input>
          </el-form-item>
        </div>

        <el-form-item label="租赁报价" prop="offer" label-width="135">
          <el-input
            v-model="ruleForm.offer"
            size="small"
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <el-form-item label="报价方式" prop="priceTermss" label-width="135">
          <el-select
            size="small"
            v-model="ruleForm.priceTermss"
            placeholder="请选择"
            multiple
          >
            <el-option
              v-for="item in priceTermss"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <div style="width: 100%">
          <el-form-item label="机械状态" prop="vehicleStatus" label-width="135">
            <el-select
              size="small"
              v-model="ruleForm.vehicleStatus"
              placeholder="请选择"
            >
              <el-option
                v-for="item in vehicleStatus"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>

        <el-form-item
          label="车辆照片(最多4张)"
          prop="vehicleImg"
          label-width="135"
          style="width: 100%"
        >
          <el-upload
            :action="action"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            :file-list="fileList"
            :limit="4"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img
              style="object-fit: contain"
              width="100%"
              :src="dialogImageUrl"
              alt=""
            />
          </el-dialog>
        </el-form-item>
        <el-form-item
          label="机械铭牌/合格证"
          prop="vehicleNameplateImg"
          label-width="135"
        >
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                handleVehicleImg(res, file, 2);
              }
            "
          >
            <img
              v-if="ruleForm.vehicleNameplateImg"
              :src="ruleForm.vehicleNameplateImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="行驶证" prop="dlImg" label-width="135">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                handleVehicleImg(res, file, 3);
              }
            "
          >
            <img v-if="ruleForm.dlImg" :src="ruleForm.dlImg" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="车险保单"
          prop="vehicleInsuranceImg"
          label-width="135"
        >
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                handleVehicleImg(res, file, 4);
              }
            "
          >
            <img
              v-if="ruleForm.vehicleInsuranceImg"
              :src="ruleForm.vehicleInsuranceImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="车主身份证"
          prop="ownerIdCardImg"
          label-width="135"
        >
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                handleVehicleImg(res, file, 5);
              }
            "
          >
            <img
              v-if="ruleForm.ownerIdCardImg"
              :src="ruleForm.ownerIdCardImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          prop="bankAccount"
          label-width="135"
          class="el-form-item-line"
        >
          <div class="line_box">
            <div class="line">驾驶员信息</div>
            <div>
              <el-button class="submit" @click="addDriver"
                >添加驾驶员
              </el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item style="width: 100%" label-width="135">
          <div
            v-for="(item, index) in ruleForm.cerePlatformDriverInfos"
            :key="index"
            class="drive"
          >
            <div class="diver_box">
              <div class="title">姓名</div>
              <el-input v-model="item.driverName" size="small"></el-input>
            </div>
            <div class="diver_box">
              <div class="title">身份证</div>
              <el-autocomplete
                popper-class="my-autocomplete"
                v-model="item.idCard"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                style="width: 50%"
                size="small"
                @select="handleSelect"
              >
              </el-autocomplete>
            </div>
            <div class="diver_box">
              <div class="title">联系电话</div>
              <el-input v-model="item.phone" size="small"></el-input>
            </div>
            <div class="diver_box">
              <div class="title">住址</div>
              <el-input v-model="item.address" size="small"></el-input>
            </div>
            <div class="diver_box">
              <div class="title">驾驶证正本</div>
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="
                  (res, file) => {
                    handleVehicleImg(res, file, 6);
                  }
                "
              >
                <img
                  v-if="item.driverLicenseFront"
                  :src="item.driverLicenseFront"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div style="display: flex" class="diver_box">
              <div class="title">驾驶证副本</div>
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="
                  (res, file) => {
                    handleVehicleImg(res, file, 7);
                  }
                "
              >
                <img
                  v-if="item.driverLicenseBack"
                  :src="item.driverLicenseBack"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <el-button
              v-if="item.delete"
              @click="deleteDriver(index)"
              class="submit"
              style="margin-left: auto"
              >删除
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button @click="backCar">返回</el-button>
        <el-button @click="submit" class="submit" :loading="loading"
          >提交
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  vehicleTypeTree,
  getSelect,
  getCityTree,
  getCitySelect,
  addVehicleInfo,
  getBrand,
  getDriverByIdCard,
  getDriverIdCardsByIdCard,
  getVehicleInfoById,
} from "@/api/user";
import { upload, createStorage } from "@/api/upload";

export default {
  data() {
    return {
      loading: false,
      ruleForm: {
        vehicleProvince: "",
        vehicleProvinceCode: "",
        vehicleCity: "",
        vehicleCityCode: "",
        vehicleCounty: "",
        vehicleCountyCode: "",
        vehicleLocation: "",
        // 车辆信息
        vehicleOwnerName: "",
        vehicleName: "",
        vehicleBrandId: null,
        deviceName: null,
        deviceSn: null,
        firstLevelType: "",
        firstLevelTypeId: "",
        secondLevelType: "",
        secondLevelTypeId: "",
        vehicleModel: "",
        vehicleProductionDate: "",
        vehicleIdentificationCode: "",
        vehicleEngineNumber: "",
        vehicleStatus: "",
        priceTermss: "",
        offer: "",
        vehicleImg: "",
        vehicleImgs: [],
        vehicleNameplateImg: "",
        dlImg: "",
        vehicleInsuranceImg: "",
        ownerIdCardImg: "",
        vehicleType: null,
        leaseType: "",
        leaseTypeCode: "",
        leaseWork: null,
        leaseUnit: null,
        // 驾驶员信息
        cerePlatformDriverInfos: [
          {
            driverName: "",
            idCard: "",
            phone: "",
            address: "",
            driverLicenseFront: "",
            driverLicenseBack: "",
          },
        ],
      },
      rules: {
        leaseType: [
          { required: true, message: "请选择租赁方式", trigger: "change" },
        ],
      },
      optionProps: {
        value: "vehicleTypeId",
        label: "vehicleTypeName",
      },
      carStatusList: [],
      carValue: [3],
      brandStatus: [],
      provinceList: [],
      cityList: [],
      areaList: [],
      brandList: [],
      driverIdList: [],
      vehicleStatus: [
        { value: "0", name: "闲置" },
        { value: "1", name: "工作中" },
        { value: "2", name: "维修中" },
      ],
      priceTermss: [
        { value: "0", name: "带司机" },
        { value: "1", name: "包燃油" },
        { value: "2", name: "包物流" },
      ],
      leaseTypeList: [
        { id: "0", name: "小时" },
        { id: "1", name: "日" },
        { id: "2", name: "月" },
        { id: "3", name: "工作量" },
      ],
      firmNumList: [],
      action: upload,
      restaurants: [],
      isAddImg: false,
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  created() {
    if (this.$route.query.type == 2) {
      getVehicleInfoById(this.$route.query.id).then((res) => {
        this.ruleForm = res.data;
        this.fileList = this.ruleForm.vehicleImgs.map((item, index) => {
          return {
            name: item,
            url: item,
          };
        });
        this.ruleForm.vehicleBrandId = Number(res.data.vehicleBrandId);
        if (this.ruleForm.cerePlatformDriverInfos == null) {
          this.ruleForm.cerePlatformDriverInfos = [
            {
              driverName: "",
              idCard: "",
              phone: "",
              address: "",
              driverLicenseFront: "",
              driverLicenseBack: "",
            },
          ];
        }
      });
    }
  },
  mounted() {
    this.getCityTree();
    this.vehicleTypeTree();
    getBrand().then((res) => {
      this.brandList = res.data;
    });
    getDriverIdCardsByIdCard().then((res) => {
      this.restaurants = res.data;
    });
    getSelect("leaseUnit").then((res) => {
      if (res.code == 200) {
        this.firmNumList = res.data;
      }
    });
  },
  methods: {
    changeBrandId(e) {
      var objVal = {};
      this.brandList.forEach((val) => {
        if (val.id == e) {
          objVal = val;
        }
      });
      this.ruleForm.vehicleBrandId = objVal.id;
      this.ruleForm.vehicleBrand = objVal.brandName;
    },
    vehicleTypeTree() {
      vehicleTypeTree().then((res) => {
        this.carStatusList = res.data;
      });
    },
    carStatusChange(value) {
      var name1 = "";
      this.carStatusList.forEach((item) => {
        if (item.vehicleTypeId == value[0]) {
          name1 = item.vehicleTypeName;
          if (value.length == 2 && item.children != null) {
            item.children.forEach((v) => {
              if (v.vehicleTypeId == value[1]) {
                name1 = item.vehicleTypeName + "/" + v.vehicleTypeName;
              }
            });
          }
        }
      });
      this.ruleForm.firstLevelType = name1.split("/")[0];
      this.ruleForm.secondLevelType = name1.split("/")[1];
      this.ruleForm.firstLevelTypeId = value[0];
      this.ruleForm.secondLevelTypeId = value[1];
    },
    changeLeaseType(e) {
      let newObj = {};
      newObj = this.leaseTypeList.find((item) => {
        return item.id === e;
      });
      this.ruleForm.leaseType = newObj.name;
      this.ruleForm.leaseTypeCode = newObj.id;
      if (e != 3) {
        this.ruleForm.leaseUnit = newObj.name;
      } else {
        this.ruleForm.leaseUnit = "";
      }
    },
    changeLeaseUnit(e) {
      let newObj = {};
      newObj = this.firmNumList.find((item) => {
        return item.dictId === e;
      });
      this.ruleForm.leaseUnit = newObj.dictName;
      this.ruleForm.leaseUnitCode = newObj.dictId;
    },
    getCityTree() {
      getCityTree().then((res) => {
        this.provinceList = res.data;
      });
    },
    provinceChange(e) {
      let provinceObj = {};
      provinceObj = this.provinceList.find((item) => {
        return item.cityId === e;
      });
      this.ruleForm.vehicleProvince = provinceObj.cityName;
      this.ruleForm.vehicleProvinceCode = provinceObj.cityId;
      getCitySelect({ cityPid: e }).then((res) => {
        this.cityList = res.data;
      });
    },
    cityChange(e) {
      let cityObj = {};
      cityObj = this.cityList.find((item) => {
        return item.cityId === e;
      });
      this.ruleForm.vehicleCity = cityObj.cityName;
      this.ruleForm.vehicleCityCode = cityObj.cityId;
      getCitySelect({ cityPid: e }).then((res) => {
        this.areaList = res.data;
      });
    },
    areaChange(e) {
      let countyObj = {};
      countyObj = this.areaList.find((item) => {
        return item.cityId === e;
      });
      this.ruleForm.vehicleCounty = countyObj.cityName;
      this.ruleForm.vehicleCountyCode = countyObj.cityId;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.ruleForm.vehicleImgs = this.fileList.map((item, index) => {
        return item.url;
      });
    },
    handleAvatarSuccess(response, file, fileList) {
      this.fileList.push({
        name: response.data.url,
        url: response.data.url,
      });
      this.ruleForm.vehicleImgs = this.fileList.map((item, index) => {
        return item.url;
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleVehicleImg(res, file, index) {
      switch (index) {
        case 2:
          this.ruleForm.vehicleNameplateImg = res.data.url;
          break;
        case 3:
          this.ruleForm.dlImg = res.data.url;
          break;
        case 4:
          this.ruleForm.vehicleInsuranceImg = res.data.url;
          break;
        case 5:
          this.ruleForm.ownerIdCardImg = res.data.url;
          break;
        case 6:
          this.ruleForm.cerePlatformDriverInfos[
            this.ruleForm.cerePlatformDriverInfos.length - 1
          ].driverLicenseFront = res.data.url;
          break;
        case 7:
          this.ruleForm.cerePlatformDriverInfos[
            this.ruleForm.cerePlatformDriverInfos.length - 1
          ].driverLicenseBack = res.data.url;
          break;
      }
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleSelect(item) {
      getDriverByIdCard({ keyword: item.value }).then((res) => {
        this.ruleForm.cerePlatformDriverInfos = res.data;
      });
    },
    createFilter(queryString) {
      return (item) => {
        return item.value.toUpperCase().match(queryString.toUpperCase());
      };
    },

    backCar() {
      this.$router.go(-1);
    },
    addDriver() {
      let isNull = false;
      let list = ["isDel", "updateTime", "updateId", "updateName"];
      this.ruleForm.cerePlatformDriverInfos.map((item) => {
        let key = Object.keys(item);
        key.map((v) => {
          if (!list.includes(v) && !item[v]) isNull = true;
        });
      });
      if (isNull === true) {
        this.$message.warning("请将数据填写完整");
        return;
      }
      this.ruleForm.cerePlatformDriverInfos.push({
        driverName: "",
        idCard: "",
        phone: "",
        address: "",
        driverLicenseFront: "",
        driverLicenseBack: "",
        delete: true,
      });
    },
    deleteDriver(index) {
      this.ruleForm.cerePlatformDriverInfos.splice(index, 1);
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          addVehicleInfo(this.ruleForm).then((res) => {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.loading = false;
            this.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ruleForm[data-v-eb592ab0] .el-form-item__error {
  left: 24%;
}

.el-icon-circle-close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 25px;
  color: #fff;
}

.ruleForm {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .el-input {
    width: 50%;
  }

  .el-form-item {
    width: 50%;
    margin-bottom: 20px;
  }

  ::v-deep .el-form-item__label {
    width: 135px;
  }

  .el-textarea {
    width: 50%;
  }

  .el-select {
    width: 50%;
  }

  ::v-deep .el-form-item__error {
    left: 30%;
  }

  .address_box {
    display: flex;

    .el-select {
      margin-right: 20px;
      width: 100%;
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .el-form-item-line {
    width: 100%;
    border-bottom: 1px solid #ddd;
  }

  .line_box {
    display: flex;
    justify-content: space-between;
  }

  .line {
    border-bottom: 4px solid #fcb110;
    width: 80px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }

  ::v-deep .el-upload--picture-card {
    background-color: #fff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
    border: 1px dashed #ccc;
  }

  .avatar {
    width: 146px;
    height: 146px;
    display: block;
    margin-right: 10px;
    object-fit: contain;
  }
}

.drive {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
  color: #606266;
  font-weight: 700;

  .diver_box {
    width: 50%;
    display: flex;
    margin-bottom: 10px;

    .title {
      width: 100px;
      margin-right: 20px;
      text-align: right;
    }
  }
}

.btn {
  display: flex;
  justify-content: right;
}
</style>
